<template>
<div>
    <is-loading v-if="isLoading" />
    <div v-else class="row">
        <div class="col-12">
            <div class="row gy-3">
                <div class="col-12">
                    <h5 class="mb-3">Sales Overview</h5>
                </div>
                <div class="row mb-4">
                    <div class="col-lg-12">
                        <div class="card shadow-sm show-worker-earnings border">
                            <div class="card-body">
                                <div class="row g-3 justify-content-between">
                                    <div class="col-sm-3">
                                        <p class="mb-1">Current Month</p>
                                        <h4 class="mb-0">${{$filters.money_format(earnings.overview.current_month)}}</h4>
                                    </div>
                                    <div class="col-sm-3">
                                        <p class="mb-1">Last Month</p>
                                        <h4 class="mb-0">${{$filters.money_format(earnings.overview.last_month)}}</h4>
                                    </div>
                                    <div class="col-sm-3">
                                        <p class="mb-1">Total Earning</p>
                                        <h4 class="mb-0">${{$filters.money_format(earnings.overview.total_earning)}}</h4>
                                    </div>
                                    <div class="col-sm-3">
                                        <p class="mb-1">Next Payout</p>
                                        <h6 class="mb-0">{{$filters.date(earnings.overview.next_payout, 2)}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-sm-flex justify-content-between">
                                    <h5 class="header-title">Earnings</h5>
                                    <div class="btn-group mb-2">
                                        <button type="button" class="btn btn-sm btn-light active">
                                            Today
                                        </button>
                                        <button type="button" class="btn btn-sm btn-light">Monthly</button>
                                    </div>
                                </div>
                                <earning-report :series-data="earnings.series" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import IsLoading from '@/components/IsLoading.vue'
import EarningReport from '@/components/widgets/EarningReport.vue'
import EarningOverview from '@/components/widgets/EarningOverview.vue'

export default {
    name: 'sale-report',
    components:{
        IsLoading,
        EarningReport,
        EarningOverview
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    data(){
        return {
            isLoading: true,
            earnings: {
                overview:{
                    current_month: 0,
                    last_month: 0,
                },
                series: []
            },
        }
    },
    created(){
        this.$http.get('/account/earnings')
        .then((response) => {
            this.isLoading = false
            if(response.data.success){
                this.earnings = response.data.data
            }
        })
    }
}
</script>

<style scoped>

</style>
