<template>
    <div class="" dir="ltr">
        <apexchart class="apex-charts" type="area" :options="chartOptions" height="260" :series="series"></apexchart>
    </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props:{
        seriesData:{
            default:() => ([])
        }
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: "vuechart-earning-report",
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        opacityFrom: 0.3,
                        opacityTo: 0.9
                    }
                },
                legend: {
                    offsetY: 5
                },
                xaxis: {
                    type: "datetime"
                },
                grid: {
                    padding: {
                        bottom: 10
                    }
                },
                yaxis: {
                    // title: {
                    //     text: "Revenue",
                    //     style: {
                    //         color: undefined,
                    //         fontSize: "13px",
                    //         cssClass: "apexcharts-yaxis-title"
                    //     }
                    // }
                },
                colors: ["#1abc9c"],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2],
                    curve: "smooth"
                }
            },
            series: [{
                name: "Total Earnings",
                data: this.seriesData.map((i) =>{
                    return [i.month, i.total_gain]
                })
            },
            ]
        };
    },
};
</script>


